import React from 'react'

import styles from '../styles/Global'

const Button = ({assetUrl, link, store}) => {
  return (
    <div
      className={styles.btnBlack}
      onClick={() => window.open(link, "_blank")}
      style={{marginRight: 10}}>
        <img src={assetUrl} alt="expo_icon"
        className={styles.btnIcon}/>
        <div className=" flex flex-col justify-start ml-4">
            <p className={`${styles.btnText} font-normal tx-xs`} style={{fontSize: 12}}>Download on</p>
            <p className={`${styles.btnText} font-bold text-sm`}>{store}</p>
        </div>

    </div>
  )
}

export default Button