import React, {useRef, useState} from 'react'
import assets from '../assets'
import styles from '../styles/Global'

import emailjs from '@emailjs/browser';

const Contact = () => {
  
  const form = useRef();
  const [formStatus, setFormStatus] = useState('Submit');
  const [errorStatus, setErrorStatus] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setFormStatus('Sending...');
    emailjs.sendForm('gmail', 'template_4shsd47', form.current, 'user_tV3FYJjcfmCZcXjMWqAKC')
      .then((result) => {
          setFormStatus('Message Sent!');
          setErrorStatus('');
      }, (error) => {
        setErrorStatus('Sorry there was an error submitting your message please try again!');
      });
  };
  
  return (
   <div style={{width: '70%', margin:'auto'}}>
     <img src={assets.logo} alt="praxtical logo"   style={{width: '180px', height: '180px'}} />

        <h1  style={{color: '#CE1226', marginBottom: '10px', fontWeight: 600}}>Contact</h1>

        <p style={{ marginTop: '10px', marginBottom: '30px'}}>Got questions, leave us a message and we will get back to you within 24-48 hours.</p>

        <form ref={form} onSubmit={sendEmail}>
        <div className="mb-3" style={{display: 'grid', gridTemplateColumns: '100%'}}>
          <label className="form-label" name="user_name" style={{color: '#CE1226'}} >
            Name
            
          </label>
          <input className="form-control" type="text" id="name" required  style={{border: 'solid', borderRadius: 5, padding: 10}}/>
        </div>


        <div className="mb-3" style={{display: 'grid', gridTemplateColumns: '100%'}}>
          <label className="form-label" name="user_email" style={{color: '#CE1226'}}>
            Email
          </label>
          <input className="form-control" type="email" id="email" required style={{border: 'solid', borderRadius: 5, padding: 10}} />
        </div>
        <div className="mb-3" style={{display: 'grid', gridTemplateColumns: '100%'}}>
          <label className="form-label" name="user_message" style={{color: '#CE1226'}}>
            Message
          </label>
          <textarea className="form-control" id="message" required  style={{border: 'solid', borderRadius: 5, padding: 10}}/>
        </div>
        <button className="btn btn-danger" type="submit" style={{backgroundColor:  '#CE1226', padding: 10, color: '#fff', borderRadius: 8, width: 'auto'}}>
          {formStatus}
        </button>
        <p style={{marginTop: 20, fontWeight: 700}}>{errorStatus}</p>
      </form>
   </div>
  )
}

export default Contact