import React from 'react';
import styles from '../styles/Global';
import assets from '../assets';
import Button from './Button'
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from "react-router-dom";

const SectionWrapper = ({ title, description, showBtn, mockupImg, banner, reverse}) => {
  return (
  <div className={`min-h-screen ${styles.section} 
  ${reverse ? styles.bgWhite : styles.bgPrimary} 
  ${banner}`}>
      <div className={`flex items-center 
      ${reverse ? styles.boxReverseClass: styles.boxClass} w-11/12 sm:w-full minmd:w-3/4`}>
        <div className={`${styles.descDiv}
        ${reverse ? "fadeRightMini" : "fadeLeftMini"}
        ${reverse ? styles.textRight : styles.textLeft}
        `}>
          <h1 className={`
          ${reverse ? styles.blackText : styles.whiteText}
          ${styles.h1Text}`}>{title}</h1>
          <p className={`${reverse ? styles.blackText : styles.whiteText}
          ${styles.descriptionText}`}>{description}</p>
          <div style={{display: 'flex'}}>
          {showBtn && (
            <Button
              assetUrl={assets.expo}
              link="https://apps.apple.com/ag/app/praxtical-to-do-list/id1658600848"
              store="App Store"
            />
            
          )}
          {showBtn && (
            <Button
              assetUrl={assets.expo02}
              link="https://play.google.com/store/apps/details?id=com.varandcompany.praxtical&pli=1"
              store="Play Store"
            />
            
          )}
          </div>
          
  
          <div style={ { marginTop: 50 } }>
          <nav>
            <ul style={{display: 'flex'}}>
              <li style={{color: '#fff', marginRight: 20}}><NavLink to='/contact'>Contact</NavLink></li>
              <li style={{color: '#fff', marginRight: 20}}><NavLink to='/terms'>Terms</NavLink></li>
              <li style={{color: '#fff'}}><NavLink to='/privacy'>Privacy</NavLink></li>
            </ul>
          </nav>
          </div>
        </div>
        <div className={`flex-1 ${styles.flexCenter} p-8 sm:px-0`}>
          <img src={mockupImg} alt="mockup" className={`
          ${reverse ? "fadeRightMini" : "fadeLeftMini"} 
          ${styles.sectionImg}`
          }/>

          
        </div>

       
      </div>
  </div>






  )
}

export default SectionWrapper