
import { Download, Features, SectionWrapper } from "./components";
import assets from "./assets";
import styles from "./styles/Global";
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from "react-router-dom";
import Terms from "./components/Terms";
import Privacy from './components/Privacy';
import Contact from "./components/Contact";

const Home = () => (
  

  <div>
    <SectionWrapper
        title="Get Praxtical ..."
              description="A simple todo list to manage all your tasks, plans, goals or events. Manage multiple lists and choose from over 50+ icons"
              showBtn
              mockupImg={assets.homeHero}
              banner="banner"
      />
     
  </div>
);








const App = () => {
  return (
    <div>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
      </Routes>
      
    </div>
  )
}

export default App
